import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { FontLoader, TextGeometry } from 'three/examples/jsm/Addons.js'


/**
 * Base
 */
// Debug
const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

//axes helper
const axesHelper = new THREE.AxesHelper()
scene.add(axesHelper)

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcaps/8.png')
matcapTexture.colorSpace = THREE.SRGBColorSpace


//fonts
const fontLoader = new FontLoader()
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const textGeometry = new TextGeometry(
            'Patricio Ramos Dev',
            {
                font, //*en js si en un objeto la key y su valor son lo mismo podemos escribirlo solo una vez
                size: 0.5,
                depth: 0.2,
                curveSegments: 5, //* cantidad de triángulos en las curvas
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4, //* cantidad de triángulos en las esquinas
            }
        )
        //* por culpa del bevel, el texto no estará exactamente centrado, moveremos la geometría ( no el mesh) para que la cámara este centrada al texto
        //*frustum culling es el calculo si el objeto esta en la pantalla
        // textGeometry.computeBoundingSphere() //* predeterminado ocupa la esfera , podemos cambiarlo a caja
        // textGeometry.computeBoundingBox() //* como calcula el espacio, rodea el mesh
        // textGeometry.translate( 
        //     - textGeometry.boundingBox.max.x * 0.5,
        //     - textGeometry.boundingBox.max.y * 0.5,
        //     - textGeometry.boundingBox.max.z * 0.5
        // )
        textGeometry.center() //*forma mas fácil de hacerlo

        const textMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
        textMaterial.wireframe = false
        const text = new THREE.Mesh(textGeometry, textMaterial)
        scene.add(text)


        console.time('donuts');
        const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45)
        const donutMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

        for (let i = 0; i < 150; i++) {
            const donut = new THREE.Mesh(donutGeometry, donutMaterial)

            //*cambiamos la posicion de las donas. Le restamos 0.5 para que tenga valores entre -0.5 a 0.5. Y multiplicamos por 10 
            donut.position.x = (Math.random() - 0.5) * 10
            donut.position.y = (Math.random() - 0.5) * 10
            donut.position.z = (Math.random() - 0.5) * 10

            //*rotamos las donas aleatoriamente, solo bastan 2 axis, y media rotación (PI)
            donut.rotation.x = Math.random() * Math.PI
            donut.rotation.y = Math.random() * Math.PI

            const scale = Math.random() //* utilizamos el mismo valor aleatorio a cada dona de la iteración para que quede proporcional.
            donut.scale.set(scale, scale, scale)
            
            scene.add(donut)
        }
        console.timeEnd('donuts');
    }
)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 2
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()